var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isSearching),expression:"isSearching"}],staticClass:"w-full bg-white mb-5"},[(_vm.liveChatLoading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.liveChatLoading),expression:"liveChatLoading"}],staticClass:"top-0 right-0 w-full min-h-screen fixed z-50",attrs:{"element-loading-text":'Loading Chat'}}):_vm._e(),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"md:w-5/6 lg:w-3/5 m-auto faq-content"},[_c('div',{staticClass:"relative mt-10"},[_vm._m(0),_c('TextField',{ref:"FAQ_SEARCH",attrs:{"label":"Search","type":"text","placeholder":"Search for questions and platforms","loading":_vm.isSearching},on:{"input":_vm.searchFAQ}})],1),_c('div',{staticClass:"faq-container gap-20"},[_c('div',{staticClass:"space-y-4"},[_c('el-tabs',{attrs:{"tab-position":_vm.tabPosition,"value":_vm.currentTab}},[_vm._l((_vm.atlasPlatforms),function(platform){return [(platform)?_c('el-tab-pane',{key:platform._id,attrs:{"name":platform._id}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(platform.name))]),_c('div',{staticClass:"p-10 w-full"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(
                        platform.FAQQuestions && platform.FAQQuestions.length
                      )?_vm._l((platform.FAQQuestions),function(faq,faqIndex){return _c('el-collapse-item',{key:faqIndex,class:[
                          'border border-transparent transition-all px-2',
                          _vm.isActive(faqIndex) && _vm.showActiveCopyQuestionBorder
                            ? 'border-atlas-blue'
                            : ''
                        ],attrs:{"name":faqIndex},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{class:_vm.isActive(faqIndex) ? '' : 'truncate'},[_vm._v(" "+_vm._s(faq.question)+" ")])]},proxy:true}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(faq.answer)}})])}):[_c('el-collapse-item',{key:"no-answers",attrs:{"name":"no-answers","title":"No questions"}},[_c('div',[_vm._v(" No answers. Please use chat feature to contact admin ")])])]],2)],1)]):_vm._e()]})],2)],1)])])]),(_vm.showExtraInfo && !_vm.liveChatIsActive)?_c('div',{staticClass:"w-5/6 rounded bg-atlas-blue text-white p-5 m-auto relative"},[_c('button',{staticClass:"absolute w-6 h-6 bg-white shadow -top-3 -right-3 rounded-full flex items-center justify-center",on:{"click":_vm.showExtraInfoHandler}},[_c('font-awesome-icon',{staticClass:"text-atlas-blue font-bold",attrs:{"icon":['fal', 'times']}})],1),_c('div',{staticClass:"flex items-center justify-between"},[_vm._m(1),_c('button',{class:`bg-white text-atlas-blue font-medium py-3 px-10 rounded-full ${
            _vm.liveChatLoading ? 'opacity-75' : ''
          }`,attrs:{"disabled":_vm.liveChatLoading},on:{"click":_vm.showLiveChatHandler}},[_vm._v(" Chat with us ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title space-y-5 mb-10"},[_c('h1',{staticClass:"font-bold text-4xl text-center"},[_vm._v(" Frequently asked questions ")]),_c('div',{staticClass:"text-center text-xl flex justify-center items-center"},[_c('p',{staticClass:"w-1/3 text-sm"},[_vm._v(" Do you need help or have question on some features? ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('h1',{staticClass:"text-2xl text-white"},[_vm._v("Still have questions?")]),_c('p',[_vm._v(" Can't find answers to what you're looking for? Chat with our support team. ")])])
}]

export { render, staticRenderFns }